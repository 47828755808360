export class Slider {
    constructor(data) {
        if (!this.set(data)) return;
        this.init();
    }

    set(data) {
        if (!data) return false;
        const {
            el,
            autoplay,
            isReviews,
            breakpoints,
            pagination,
            loop,
            blockLastSlides,
            isOverflow = true,
        } = data;
        this.root = el;
        this.isOverflow = isOverflow;
        if (this.isOverflow) this.root.style.overflow = 'hidden';
        this.autoplay = autoplay;
        this.isReviews = isReviews || false;
        this.breakpoints = breakpoints || null;
        this.pagination = pagination || null;
        this.loop = loop === undefined ? true : loop;
        this.blockLastSlides =
            blockLastSlides === undefined ? false : blockLastSlides;
        this.slideChangeEvent = null;
        this.slidesPerView = 1;
        this.marginRight = 0;
        this.slideWidth = 1;
        this.singleBulletWidth = 0;

        this.slides = this.root.querySelectorAll('.slider__slide');
        if (this.slides.length === 0) return false;

        this.container = this.root.querySelector('.slider__container');
        this.sliderWrapper = this.root.querySelector('.slider__wrapper');
        this.sliderPagination = this.root.querySelector('.slider__pagination');
        this.arrowsWrapper = this.root.querySelector('.slider__arrows');
        this.prevArrow = this.arrowsWrapper
            ? this.arrowsWrapper.querySelector('.-prev')
            : null;
        this.nextArrow = this.arrowsWrapper
            ? this.arrowsWrapper.querySelector('.reviews-slider__arrow.-next')
            : null;

        this.autoplayInterval = null;
        this.scrollbarElementDiv = null;

        this.positions = [
            {
                position: 0,
                x: 0, // pozycja 0
                y: 0,
                scale: 1,
            },
            {
                position: 'left',
                x: -1.0625, // pozycja 1
                y: -0.1875,
                scale: 0.35,
            },
            {
                position: 'right',
                x: 1, // pozycja 2
                y: -0.375,
                scale: 0.35,
            },
            {
                position: 'left',
                x: -0.6875, // pozycja 3
                y: 0.125,
                scale: 0.5,
            },
            {
                position: 'left',
                x: -0.75, // pozycja 4
                y: -0.4375,
                scale: 0.35,
            },
            {
                position: 'right',
                x: 1.0625, // pozycja 5
                y: 0.1875,
                scale: 0.5,
            },
            {
                position: 'right',
                x: 0.625, // pozycja 6
                y: -0.0625,
                scale: 0.5,
            },
        ];

        this.slidesNum = this.slides.length;
        this.previousSlideIndex = this.slidesNum - 1;
        this.activeSlideIndex = 0;
        this.nextSlideIndex = 1;

        this.containerWidth = 0;
        this.containerHeight = 0;
        this.touchstartX = 0;
        this.previousSliderWrapperTransform = 0;
        this.touchendX = 0;
        this.desktopTouchX = 0;

        this.isClassToBeDestoyed = false;
        this.hasBeenDestroyed = false;

        this.boundSliderDrag = null;
        this.boundScrollbarElementDrag = null;
        this.boundSlideClick = null;
        this.controller = new AbortController();
        this.signal = this.controller.signal;

        return true;
    }

    init() {
        const signal = this.signal;
        if (this.breakpoints && this.sliderWrapper) {
            this.initBreakpoints();
            window.addEventListener(
                'resize',
                () => {
                    this.initBreakpoints();
                },
                { signal }
            );
        }

        if (this.isReviews) {
            this.getContainerSizes();
            window.addEventListener(
                'resize',
                () => {
                    this.getContainerSizes();
                },
                { signal }
            );
            this.initImagesClick();
            window.addEventListener(
                'resize',
                () => {
                    this.setSlidesPositions(true);
                },
                { signal }
            );
        }
        this.root.addEventListener('onSlideChange', e =>
            this.handleOnChangeSlide(e)
        );
        if (this.isReviews) this.changeSlide(0);
        window.addEventListener(
            'resize',
            () => {
                this.handleSliderMove();
            },
            { signal }
        );
        this.initAutoplay();
        if (this.arrowsWrapper) this.initArrows();
        if (this.sliderWrapper) this.initTouch();
        if (this.pagination) this.initScrollbarElementDivTouch();
        if (this.sliderPagination) {
            this.initPagination();
            this.initScrollbarElementWidth();
            window.addEventListener(
                'resize',
                () => {
                    this.initScrollbarElementWidth();
                },
                { signal }
            );
        }
        this.handlePaginationBulletsVisibility();
        window.addEventListener(
            'resize',
            () => {
                this.handlePaginationBulletsVisibility();
                if (
                    this.blockLastSlides &&
                    this.slidesPerView > 1 &&
                    Math.round(this.activeSlideIndex + this.slidesPerView) >
                        this.slidesNum
                ) {
                    this.changeSlide(
                        this.slidesNum - this.slidesPerView >= 0
                            ? this.slidesNum - this.slidesPerView
                            : 0,
                        true
                    );
                }
            },
            { signal }
        );

        this.initSlideClick();
    }

    reinit() {
        this.slides = this.root.querySelectorAll('.slider__slide');
        this.slidesNum = this.slides.length;
        this.initBreakpoints();
        if (this.sliderPagination) {
            this.sliderPagination.replaceChildren();
            this.initPagination();
        }
        this.changeSlide(0);
    }

    getContainerSizes() {
        if (!this.container) return;
        this.containerWidth = this.container.offsetWidth;
        this.containerHeight = this.container.offsetHeight;
    }

    initArrows() {
        if (this.prevArrow) {
            this.prevArrow.addEventListener('click', () => {
                this.changeSlide(
                    this.activeSlideIndex === 0
                        ? this.slidesNum - 1
                        : this.activeSlideIndex - 1
                );
            });
            this.prevArrow.addEventListener('keydown', e => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    this.changeSlide(
                        this.activeSlideIndex === 0
                            ? this.slidesNum - 1
                            : this.activeSlideIndex - 1
                    );
                }
            });
        }

        if (this.nextArrow) {
            this.nextArrow.addEventListener('click', () => {
                this.changeSlide(
                    this.activeSlideIndex + 1 === this.slidesNum
                        ? 0
                        : this.activeSlideIndex + 1
                );
            });
            this.nextArrow.addEventListener('keydown', e => {
                if (e.key === 'Enter') {
                    this.changeSlide(
                        this.activeSlideIndex + 1 === this.slidesNum
                            ? 0
                            : this.activeSlideIndex + 1
                    );
                }
            });
        }
    }

    initTouch() {
        const signal = this.signal;
        // Mobile
        const closestScrollable = element => {
            while (element && element !== document.body) {
                if (getComputedStyle(element).overflow.includes('auto')) {
                    return element;
                }
                element = element.parentElement;
            }
            return null;
        };
        this.sliderWrapper.addEventListener(
            'touchstart',
            e => {
                const scrollableElement = closestScrollable(e.target);
                const isInsideSliderSlide =
                    scrollableElement &&
                    scrollableElement.closest('.slider__slide');
                if (!isInsideSliderSlide) {
                    this.touchstartX = e.pageX || e.changedTouches[0].pageX;
                    const sliderWrapperTransformArray =
                        this.sliderWrapper.style.transform.match(/-?\d+\.?\d*/);
                    this.previousSliderWrapperTransform =
                        sliderWrapperTransformArray
                            ? parseInt(sliderWrapperTransformArray[0])
                            : 0;
                    this.boundSliderDrag = this.handleDrag.bind(this);
                    this.root.addEventListener(
                        'touchmove',
                        this.boundSliderDrag,
                        {
                            passive: false,
                        }
                    );
                }
            },
            {
                passive: true,
            }
        );

        this.sliderWrapper.addEventListener('touchend', e => {
            this.touchendX = e.pageX;
            const sliderWrapperTransformArray =
                this.sliderWrapper.style.transform.match(/-?\d+\.?\d*/);
            this.previousSliderWrapperTransform = sliderWrapperTransformArray
                ? parseInt(sliderWrapperTransformArray[0])
                : 0;
            // this.handleSwipe();
        });

        window.addEventListener('touchend', () => {
            this.root.removeEventListener('touchmove', this.boundSliderDrag);
        });

        // Desktop
        this.sliderWrapper.addEventListener('mousedown', e => {
            if (e.button !== 0) return;
            this.touchstartX = e.pageX;
            const sliderWrapperTransformArray =
                this.sliderWrapper.style.transform.match(/-?\d+\.?\d*/);
            this.previousSliderWrapperTransform = sliderWrapperTransformArray
                ? parseInt(sliderWrapperTransformArray[0])
                : 0;
            this.boundSliderDrag = this.handleDrag.bind(this);
            this.root.addEventListener('mousemove', this.boundSliderDrag);
        });

        this.sliderWrapper.addEventListener('mouseup', e => {
            this.touchendX = e.pageX;
            const sliderWrapperTransformArray =
                this.sliderWrapper.style.transform.match(/-?\d+\.?\d*/);
            this.previousSliderWrapperTransform = sliderWrapperTransformArray
                ? parseInt(sliderWrapperTransformArray[0])
                : 0;
            // this.handleSwipe();
        });

        document.addEventListener(
            'mouseup',
            () => {
                this.root.removeEventListener(
                    'mousemove',
                    this.boundSliderDrag
                );
                setTimeout(() => {
                    this.slides.forEach(item => {
                        item.style.pointerEvents = 'all';
                    });
                }, 100);
            },
            { signal }
        );

        window.addEventListener(
            'mouseup',
            () => {
                this.root.removeEventListener(
                    'mousemove',
                    this.boundSliderDrag
                );
            },
            { signal }
        );
    }

    initScrollbarElementDivTouch() {
        if (!this.scrollbarElementDiv) return;
        const signal = this.signal;

        // Mobile scrollbar
        this.scrollbarElementDiv.addEventListener('touchstart', e => {
            this.touchstartX = e.pageX || e.changedTouches[0].pageX;
            this.scrollbarElementDiv.style.transition = `height 0.3s linear, transform 0s`;
            const sliderWrapperTransformArray =
                this.sliderWrapper.style.transform.match(/-?\d+\.?\d*/);
            this.previousSliderWrapperTransform = sliderWrapperTransformArray
                ? parseInt(sliderWrapperTransformArray[0])
                : 0;
            this.boundScrollbarElementDrag =
                this.handleScrollbarDrag.bind(this);
            this.root.addEventListener(
                'touchmove',
                this.boundScrollbarElementDrag,
                { passive: true }
            );
        });

        window.addEventListener(
            'touchend',
            e => {
                if (window.innerWidth < 991.98) {
                    this.touchendX = e.pageX;
                    const sliderWrapperTransformArray =
                        this.sliderWrapper.style.transform.match(/-?\d+\.?\d*/);
                    this.previousSliderWrapperTransform =
                        sliderWrapperTransformArray
                            ? parseInt(sliderWrapperTransformArray[0])
                            : 0;
                    this.handleSwipe();
                    this.scrollbarElementDiv.style.removeProperty('transition');
                }
            },
            { signal }
        );

        window.addEventListener(
            'touchend',
            () => {
                this.root.removeEventListener(
                    'mousemove',
                    this.boundScrollbarElementDrag
                );
            },
            { signal }
        );

        // Desktop scrollbar
        this.scrollbarElementDiv.addEventListener('mousedown', e => {
            this.touchstartX = e.pageX;
            this.scrollbarElementDiv.style.transition = `height 0.3s linear, transform 0s`;
            const sliderWrapperTransformArray =
                this.sliderWrapper.style.transform.match(/-?\d+\.?\d*/);
            this.previousSliderWrapperTransform = sliderWrapperTransformArray
                ? parseInt(sliderWrapperTransformArray[0])
                : 0;
            this.boundScrollbarElementDrag =
                this.handleScrollbarDrag.bind(this);
            this.root.addEventListener(
                'mousemove',
                this.boundScrollbarElementDrag
            );
        });

        document.addEventListener(
            'mouseup',
            e => {
                this.touchendX = e.pageX;
                const sliderWrapperTransformArray =
                    this.sliderWrapper.style.transform.match(/-?\d+\.?\d*/);
                this.previousSliderWrapperTransform =
                    sliderWrapperTransformArray
                        ? parseInt(sliderWrapperTransformArray[0])
                        : 0;
                this.handleSwipe();
                this.scrollbarElementDiv.style.removeProperty('transition');
            },
            { signal }
        );

        window.addEventListener(
            'mouseup',
            () => {
                this.root.removeEventListener(
                    'mousemove',
                    this.boundScrollbarElementDrag
                );
            },
            { signal }
        );
    }

    handleDrag(e) {
        let currentX = 0;

        if (this.slidesPerView > this.slidesNum) return;

        if (window.innerWidth > 991.98) {
            currentX = e.clientX || 0;
        } else {
            if (e.cancelable) e.preventDefault();
            currentX = e.changedTouches[0].clientX || 0;
        }
        const deltaX = currentX - this.touchstartX;

        let transformToSet = this.previousSliderWrapperTransform + deltaX;
        const slidesNumForNumber =
            Math.round(this.slidesPerView) > 1 && window.innerWidth < 991.98
                ? Math.round(this.slidesPerView - 1)
                : Math.round(this.slidesPerView);

        const number = this.slidesNum - slidesNumForNumber;
        const maxNegativeTransform = this.marginRight
            ? number * this.slideWidth * -1 -
              number * (this.marginRight / this.slidesPerView)
            : number * this.slideWidth * -1;

        if (transformToSet > 0) transformToSet = 0;
        if (transformToSet < maxNegativeTransform)
            transformToSet = maxNegativeTransform;

        if (this.hasBeenDestroyed || this.isReviews) {
            this.sliderWrapper.style.transform = `translateX(0px)`;
            if (this.scrollbarElementDiv)
                this.scrollbarElementDiv.style.transform = `translate(0px, -50%)`;
            return;
        }

        this.sliderWrapper.style.transform = `translateX(${transformToSet}px)`;

        if (this.scrollbarElementDiv) {
            const percentValueOfCard = (transformToSet * -1) / this.slideWidth;
            this.scrollbarElementDiv.style.transform = `translate(${
                this.singleBulletWidth * percentValueOfCard
            }px, -50%)`;
        }
    }

    handleScrollbarDrag(e) {
        let currentX = 0;
        if (window.innerWidth > 991.98) {
            currentX = e.clientX || 0;
        } else {
            currentX = e.changedTouches ? e.changedTouches[0].clientX : 0;
        }
        const deltaX = currentX - this.touchstartX;
        const scrollbarElementDivStartTransform =
            ((this.previousSliderWrapperTransform * -1) / this.slideWidth) *
            this.singleBulletWidth;
        let transformToSet = deltaX + scrollbarElementDivStartTransform;

        const slidesNumForNumber =
            Math.round(this.slidesPerView) > 1 && window.innerWidth < 991.98
                ? Math.round(this.slidesPerView - 1)
                : Math.round(this.slidesPerView);
        const number = this.slidesNum - slidesNumForNumber;

        if (transformToSet < 0) transformToSet = 0;
        if (transformToSet >= number * this.singleBulletWidth)
            transformToSet = number * this.singleBulletWidth;

        this.scrollbarElementDiv.style.transform = `translate(${transformToSet}px, -50%)`;

        const wrapperTransform =
            (transformToSet / this.singleBulletWidth) * this.slideWidth * -1 -
            (this.marginRight
                ? number * (this.marginRight / this.slidesPerView)
                : 0);

        this.sliderWrapper.style.transform = `translateX(${wrapperTransform}px)`;
    }

    initPagination() {
        let bulletClass = 'slider__bullet';
        if (this.pagination?.bulletClass)
            bulletClass += ` ${this.pagination.bulletClass}`;

        this.slides.forEach((slide, key) => {
            if (!slide.classList.contains('slider__slide')) return;
            const span = document.createElement('span');
            span.classList.add(...bulletClass.split(' '));
            span.setAttribute('tabindex', 0);
            this.sliderPagination.appendChild(span);
        });

        this.scrollbarElementDiv = document.createElement('div');
        this.scrollbarElementDiv.classList.add(
            'slider__scrollbar-bullet',
            'd-flex',
            'align-items-center',
            'justify-content-center'
        );
        const img = document.createElement('img');
        img.src = sliderData.dragImg;
        img.alt = sliderData.dragAlt;
        img.classList.add('slider__scrollbar-bullet-img');
        this.scrollbarElementDiv.appendChild(img);
        this.sliderPagination.appendChild(this.scrollbarElementDiv);

        if (this.blockLastSlides) this.handlePaginationBulletsVisibility();
        this.handleBulletClick();
        this.initScrollbarElementDivTouch();
    }

    handlePaginationBulletsVisibility() {
        if (!this.sliderPagination || this.hasBeenDestroyed) return;
        const bullets =
            this.sliderPagination.querySelectorAll('.slider__bullet');
        if (bullets.length === 0 || !this.blockLastSlides) return;
        const numberOfBulletsToHide = Math.round(this.slidesPerView - 1);
        bullets.forEach(item => item.classList.remove('-hide'));
        if (numberOfBulletsToHide > 0) {
            const bulletsToHide = Array.from(bullets).slice(
                -numberOfBulletsToHide
            );
            bulletsToHide.forEach(item => item.classList.add('-hide'));
        } else {
            bullets.forEach(item => item.classList.remove('-hide'));
        }
    }

    initSlideClick() {
        if (!this.slides || this.slides.length === 0) return;
        const signal = this.signal;

        this.boundSlideClick = this.handleSlideClick.bind(this);

        this.slides.forEach(slide => {
            slide.addEventListener('dragstart', this.boundSlideClick);
        });
    }

    handleSlideClick(e) {
        e.currentTarget.style.pointerEvents = 'none';
    }

    initScrollbarElementWidth() {
        setTimeout(() => {
            const paginationBullets =
                this.sliderPagination.querySelectorAll('.slider__bullet');
            if (!this.scrollbarElementDiv || paginationBullets.length === 0)
                return;
            this.singleBulletWidth = paginationBullets[0].offsetWidth;
            this.scrollbarElementDiv.style.width = `${this.singleBulletWidth}px`;
        }, 50);
    }

    handleBulletClick() {
        const paginationBullets =
            this.sliderPagination.querySelectorAll('.slider__bullet');
        if (paginationBullets.length === 0) return;

        paginationBullets.forEach((bullet, key) => {
            bullet.addEventListener('click', () => {
                if (!bullet.classList.contains('-active')) {
                    this.scrollbarElementDiv.style.transform = `translate(${
                        key * this.singleBulletWidth
                    }px, -50%)`;
                    this.changeSlide(key);
                }
            });
            bullet.addEventListener('keydown', e => {
                if (
                    !bullet.classList.contains('-active') &&
                    e.key === 'Enter'
                ) {
                    e.preventDefault();
                    this.scrollbarElementDiv.style.transform = `translate(${
                        key * this.singleBulletWidth
                    }px, -50%)`;
                    this.changeSlide(key);
                }
            });
        });
    }

    initBreakpoints() {
        if (!this.breakpoints) return;
        let highestValue = {
            slidesPerView: 1,
            marginRight: 0,
        };
        let highestBreakpoint = -1;
        for (const [key, value] of Object.entries(this.breakpoints)) {
            const { slidesPerView, marginRight, destroy } = value;
            if (destroy !== undefined) {
                this.isClassToBeDestoyed = true;
            }
            if (
                document.body.clientWidth >= parseInt(key) &&
                parseInt(key) > highestBreakpoint
            ) {
                if (slidesPerView) highestValue.slidesPerView = slidesPerView;
                if (marginRight) highestValue.marginRight = marginRight;
                if (destroy) highestValue.destroy = true;

                highestBreakpoint = parseInt(key);
            }
        }
        this.handleBreakpointValue(highestValue);
    }

    handleBreakpointValue(value) {
        if (!value) return;
        const { slidesPerView, marginRight, destroy } = value;
        this.slidesPerView = slidesPerView || 1;
        this.marginRight = marginRight || 0;

        if (this.isClassToBeDestoyed && destroy) {
            this.hasBeenDestroyed = true;
        } else if (
            this.isClassToBeDestoyed &&
            !destroy &&
            this.hasBeenDestroyed
        ) {
            this.hasBeenDestroyed = false;
        }

        if (this.slidesPerView) this.handleSlideWidth();
        this.handleSlideMarginRight();
    }

    handleSlideWidth() {
        if (this.hasBeenDestroyed) {
            this.slides.forEach(slide => {
                slide.style.removeProperty('width');
                slide.style.removeProperty('marginRight');
            });
            return;
        }
        const sliderWidth = this.sliderWrapper.offsetWidth;

        this.slideWidth = sliderWidth / this.slidesPerView;

        this.slides.forEach(slide => {
            slide.style.width = `${
                this.slidesPerView === 1
                    ? 100 + '%'
                    : this.marginRight
                    ? sliderWidth / this.slidesPerView -
                      (this.marginRight * (this.slidesPerView - 1)) /
                          this.slidesPerView +
                      'px'
                    : sliderWidth / this.slidesPerView + 'px'
            }`;
        });
    }

    handleSlideMarginRight() {
        this.slides.forEach(slide => {
            slide.style.marginRight = `${
                this.marginRight && !this.hasBeenDestroyed
                    ? this.marginRight
                    : 0
            }px`;
        });
    }

    handleSwipe() {
        if (this.isReviews) {
            if (this.touchendX < this.touchstartX) {
                this.changeSlide(
                    this.activeSlideIndex + 1 === this.slidesNum
                        ? 0
                        : this.activeSlideIndex + 1
                );
            }
            if (this.touchendX > this.touchstartX) {
                this.changeSlide(
                    this.activeSlideIndex === 0
                        ? this.slidesNum - 1
                        : this.activeSlideIndex - 1
                );
            }
        } else {
            let changeNumber =
                (this.previousSliderWrapperTransform * -1) /
                (this.marginRight + this.slideWidth);

            if (Math.abs(changeNumber - this.activeSlideIndex) >= 0.2) {
                if (changeNumber > this.activeSlideIndex) {
                    changeNumber = Math.ceil(changeNumber);
                } else {
                    changeNumber = Math.floor(changeNumber);
                }
            } else {
                changeNumber = this.activeSlideIndex; // Prevents unnecessary slide change
            }

            if (changeNumber === -0) changeNumber = 0;

            const slideToChange = Math.round(changeNumber);
            this.scrollbarElementDiv.style.transform = `translate(${
                slideToChange * this.singleBulletWidth
            }px, -50%)`;
            this.changeSlide(slideToChange);
        }
    }

    initImagesClick() {
        this.slides.forEach(slide => {
            const key = parseInt(slide.getAttribute('data-key'));
            const image = slide.querySelector('.reviews-slider__slide-image');
            if (image) {
                image.addEventListener('click', () => {
                    if (key !== this.activeSlideIndex) {
                        this.changeSlide(key);
                    }
                });
            }
        });
    }

    changeSlide(slideToChange, forceReset = false) {
        if (
            !forceReset &&
            !this.loop &&
            slideToChange + 1 === this.slidesNum &&
            Math.round(this.slidesPerView - 1) !== 0
        )
            return;
        if (
            !forceReset &&
            this.blockLastSlides &&
            Math.round(this.slidesPerView - 1) !== 0 &&
            slideToChange + this.slidesPerView === this.slidesNum + 1
        )
            return;
        this.activeSlideIndex = slideToChange;
        this.previousSlideIndex = this.getPreviousSlideIndex();
        this.nextSlideIndex = this.getNextSlideIndex();

        this.slideChangeEvent = new CustomEvent('onSlideChange', {
            detail: {
                root: this.root,
                currentSlide: this.activeSlideIndex,
                slidesPerView: this.slidesPerView,
                slideWidth: this.slideWidth,
            },
        });
        this.root.dispatchEvent(this.slideChangeEvent);
    }

    handleOnChangeSlide(e) {
        if (!this.hasBeenDestroyed) {
            this.setSlidesPositions();
            this.setSlidesVisibilities();
            if (this.isReviews) this.handleTheme();
            this.setSlidesClasses();
            this.handleSliderMove();
        }
    }

    getPreviousSlideIndex() {
        if (this.activeSlideIndex === this.slidesNum) {
            return 0;
        } else if (this.activeSlideIndex === 0) {
            return this.slidesNum - 1;
        } else {
            return this.activeSlideIndex - 1;
        }
    }

    getNextSlideIndex() {
        if (this.activeSlideIndex + 1 === this.slidesNum) {
            return 0;
        } else {
            return this.activeSlideIndex + 1;
        }
    }

    setSlidesClasses() {
        const prevSlide = this.root.querySelector('.slider__slide.-prev');
        const activeSlide = this.root.querySelector('.slider__slide.-active');
        const nextSlide = this.root.querySelector('.slider__slide.-next');
        if (prevSlide) prevSlide.classList.remove('-prev');
        if (activeSlide) activeSlide.classList.remove('-active');
        if (nextSlide) nextSlide.classList.remove('-next');
        if (this.slides[this.previousSlideIndex])
            this.slides[this.previousSlideIndex].classList.add('-prev');
        if (this.slides[this.activeSlideIndex])
            this.slides[this.activeSlideIndex].classList.add('-active');
        if (this.slides[this.nextSlideIndex])
            this.slides[this.nextSlideIndex].classList.add('-next');
    }

    swapSidesElements = (arr, pos1, pos2) => {
        const temp = arr[pos1];
        arr[pos1] = arr[pos2];
        arr[pos2] = temp;
        return arr;
    };

    movePositionsArrayElements(arr, fromIndex, toIndex) {
        const targetElement = arr[toIndex % 7];

        const { position: targetPosition } = targetElement;

        if (targetPosition !== 0) {
            if (targetPosition === 'left') {
                let firstPosition = null;
                let secondPosition = null;
                this.positions.forEach((position, key) => {
                    if (position.position === 'right') {
                        firstPosition = key;
                        if (firstPosition && !secondPosition)
                            secondPosition = key;
                    }
                });
                if (firstPosition && secondPosition)
                    this.swapSidesElements(
                        this.positions,
                        firstPosition,
                        secondPosition
                    );
            } else {
                let firstPosition = null;
                let secondPosition = null;
                this.positions.forEach((position, key) => {
                    if (position.position === 'left') {
                        firstPosition = key;
                        if (firstPosition && !secondPosition)
                            secondPosition = key;
                    }
                });
                if (firstPosition && secondPosition)
                    this.swapSidesElements(
                        this.positions,
                        firstPosition,
                        secondPosition
                    );
            }
        }

        const element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex % 7, 0, element);

        return arr;
    }

    setSlidesPositions(dontScale = false) {
        if (!this.container) return;
        let zeroPositionKey;

        this.positions.forEach((position, key) => {
            if (position.position === 0) {
                zeroPositionKey = key;
            }
        });

        this.movePositionsArrayElements(
            this.positions,
            zeroPositionKey,
            this.activeSlideIndex
        );

        this.slides.forEach((slide, key) => {
            const slidePositionDataAttribute =
                slide.getAttribute('data-position-key');
            const { x, y, scale } = this.positions[slidePositionDataAttribute];
            const image = slide.querySelector('.reviews-slider__slide-image');
            if (image) {
                if (dontScale) {
                    image.style.transform = `translate(${
                        this.containerWidth * 0.5 * this.randomizeCoords(x)
                    }px, ${
                        this.containerHeight * 0.5 * this.randomizeCoords(y)
                    }px scale(${scale})`;
                } else {
                    image.style.transform = `translate(${
                        this.containerWidth * 0.5 * this.randomizeCoords(x)
                    }px, ${
                        this.containerHeight * 0.5 * this.randomizeCoords(y)
                    }px) scale(${
                        scale === 1 ? scale : this.randomScale(scale)
                    })`;
                }
            }
        });
    }

    randomizeCoords(coord) {
        return (Math.random() * 0.2 + 0.85) * coord;
    }

    randomScale(scale) {
        return (Math.random() * 0.1 + 0.95) * scale;
    }

    setSlidesVisibilities() {
        const activeGroup = this.getElementGroup(7);

        for (let index = 0; index < 7; index++) {
            const positionSlides = this.root.querySelectorAll(
                `[data-position-key='${index}']`
            );

            positionSlides.forEach((positionSlide, key) => {
                if (key === activeGroup) {
                    positionSlide.classList.remove('-hide');
                } else if (key !== activeGroup && positionSlides.length > 1) {
                    positionSlide.classList.add('-hide');
                }
            });
        }
    }

    getElementGroup(groupSize) {
        let groupIndex = Math.floor(this.activeSlideIndex / groupSize);
        if (groupIndex === -1) {
            return null;
        } else {
            return groupIndex;
        }
    }

    handleTheme() {
        let previousClass = null;
        this.root.classList.forEach(elementClass => {
            if (elementClass.includes('-theme')) {
                previousClass = elementClass;
                this.root.classList.remove(elementClass);
            }
        });
        this.root.classList.add(`${this.generateThemeClass(previousClass)}`);
    }

    generateThemeClass(prevClass) {
        let newClass = `-theme-${Math.floor(Math.random() * 4) + 1}`;
        if (!prevClass) {
            return newClass;
        } else {
            while (prevClass === newClass) {
                newClass = `-theme-${Math.floor(Math.random() * 4) + 1}`;
            }
            return newClass;
        }
    }

    initAutoplay() {
        if (!this.autoplay) return;

        this.runAutoplay();
        this.sliderWrapper.addEventListener('mouseover', () => {
            if (this.autoplayInterval) clearInterval(this.autoplayInterval);
        });
        this.sliderWrapper.addEventListener('mouseleave', () => {
            this.runAutoplay();
        });
        this.sliderWrapper.addEventListener('touchstart', () => {
            if (this.autoplayInterval) clearInterval(this.autoplayInterval);
        });
        this.sliderWrapper.addEventListener('touchend', () => {
            this.runAutoplay();
        });
    }

    runAutoplay() {
        this.autoplayInterval = setInterval(() => {
            this.changeSlide(
                this.activeSlideIndex + 1 === this.slidesNum
                    ? 0
                    : this.activeSlideIndex + 1
            );
        }, this.autoplay);
    }

    handleSliderMove() {
        if (!this.sliderWrapper) return;
        if (this.hasBeenDestroyed) {
            this.sliderWrapper.style.removeProperty('transform');
            this.sliderWrapper.style.removeProperty('display');
            return;
        }

        if (!this.isReviews) {
            this.sliderWrapper.style.transform = `translatex(${
                this.marginRight
                    ? this.activeSlideIndex * this.slideWidth * -1 -
                      this.activeSlideIndex *
                          (this.marginRight / this.slidesPerView)
                    : this.activeSlideIndex * this.slideWidth * -1
            }px)`;
        }

        if (this.scrollbarElementDiv) {
            setTimeout(() => {
                this.scrollbarElementDiv.style.transform = `translate(${
                    this.singleBulletWidth * this.activeSlideIndex
                }px, -50%)`;
            }, 100);
        }
    }

    totalDestroy() {
        this.root.removeEventListener('touchmove', this.boundSliderDrag);
        this.root.removeEventListener('mousemove', this.boundSliderDrag);
        this.root.removeEventListener('mousemove', this.boundSliderDrag);
        this.root.removeEventListener(
            'mousemove',
            this.boundScrollbarElementDrag
        );
        this.root.removeEventListener(
            'mousemove',
            this.boundScrollbarElementDrag
        );
        this.controller.abort();
    }
}
